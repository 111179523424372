.bx-wrapper
  position relative
  margin 0 auto 60px
  padding 0
  *zoom 1
  -ms-touch-action pan-y
  touch-action pan-y
  img
    max-width 100%
    display block
  .bx-loading
    min-height 50px
    background url(images/bx_loader.gif) center center no-repeat #fff
    height 100%
    width 100%
    position absolute
    top 0
    left 0
    z-index 2000
  .bx-pager
    text-align center
    font-size .85em
    font-family Arial
    font-weight 700
    color #666
    padding-top 20px
    &.bx-default-pager
      a
        background #fff
        text-indent -9999px
        display block
        width 18px
        height 18px
        margin 0 5px
        outline 0
        border-radius 50%
  .bx-pager-item
    font-size 0
    line-height 0
  .bx-prev
    left 10px
    background url(images/controls.png) no-repeat 0 -32px
  .bx-next
    right 10px
    background url(images/controls.png) no-repeat -43px -32px
  .bx-pager 
    bottom 17px
  .bx-controls-direction
    a
      position absolute
      top 50%
      margin-top -16px
      outline 0
      width 32px
      height 32px
      text-indent -9999px
      z-index 9999
      &.disabled
        display none
  .bx-controls-auto
    text-align center
    .bx-start
      display block
      text-indent -9999px
      width 10px
      height 11px
      outline 0
      background url(images/controls.png) -86px -11px no-repeat
      margin 0 3px
    .bx-stop
      display block
      text-indent -9999px
      width 9px
      height 11px
      outline 0
      background url(images/controls.png) -86px -44px no-repeat
      margin 0 3px
  .bx-controls
    &.bx-has-controls-auto
      &.bx-has-pager
        .bx-pager
          text-align left
          width 80%
        .bx-controls-auto
          right 0
          width 35px
  .bx-caption
    position absolute
    bottom 0
    left 0
    background #666
    background rgba(80, 80, 80, .75)
    width 100%
    span
      color #fff
      font-family Arial
      display block
      font-size .85em
      padding 10px

.bxslider
  margin 0
  padding 0

ul
  &.bxslider
    list-style none

.bx-viewport
  -webkit-transform translatez(0)

.bx-wrapper .bx-controls-auto,
.bx-wrapper .bx-pager
  position absolute
  width 100%

.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus,
.bx-wrapper .bx-pager.bx-default-pager a:hover
  background #ed1c24

.bx-wrapper .bx-controls-auto .bx-controls-auto-item,
.bx-wrapper .bx-pager-item
  display inline-block
  *zoom 1
  *display inline

.bx-wrapper .bx-prev:focus,
.bx-wrapper .bx-prev:hover
  background-position 0 0

.bx-wrapper .bx-next:focus,
.bx-wrapper .bx-next:hover
  background-position -43px 0

.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus,
.bx-wrapper .bx-controls-auto .bx-start:hover
  background-position -86px 0

.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus,
.bx-wrapper .bx-controls-auto .bx-stop:hover
  background-position -86px -33px

