@font-face
	font-family 'Intro-Black'
	src url('../fonts/Intro-Black/Intro-Black.eot?#iefix') format('embedded-opentype'),  
	url('../fonts/Intro-Black/Intro-Black.woff') format('woff'), 
	url('../fonts/Intro-Black/Intro-Black.ttf')  format('truetype'), 
	url('../fonts/Intro-Black/Intro-Black.svg#Intro-Black') format('svg')
	font-weight normal
	font-style normal
@font-face
	font-family 'GothamPro-Bold'
	src url('../fonts/GothamPro-Bold/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/GothamPro-Bold/GothamPro-Bold.otf')  format('opentype'),url('../fonts/GothamPro-Bold/GothamPro-Bold.woff') format('woff'), url('../fonts/GothamPro-Bold/GothamPro-Bold.ttf')  format('truetype'), url('../fonts/GothamPro-Bold/GothamPro-Bold.svg#GothamPro-Bold') format('svg')
	font-weight normal
	font-style normal
@font-face
	font-family 'Intro-Thin'
	src url('../fonts/Intro-Thin/Intro-Thin.eot?#iefix') format('embedded-opentype'),  
	url('../fonts/Intro-Thin/Intro-Thin.otf')  format('opentype'),
	url('../fonts/Intro-Thin/Intro-Thin.woff') format('woff'), 
	url('../fonts/Intro-Thin/Intro-Thin.ttf')  format('truetype'), 
	url('../fonts/Intro-Thin/Intro-Thin.svg#Intro-Thin') format('svg')
	font-weight normal
	font-style normal




@import 'variables';
@import 'reset';
@import '../bower_components/bxslider-4/dist/jquery.bxslider.min.styl';
@import '../sty/animate.styl';
@import '../sty/mfp.styl';

.header 
	padding-top 70px
.logo a
	text-align: center;
	font-family: 'Intro-Black';
	font-size: 61px;
	text-transform: uppercase;
	color: #000;
	display: inline-block;
	text-decoration: none;
	background-color: #fff;
	padding: 0 5px;
.logo-slogan 
	font-size 16px
	line-height 15px
	color #000
	font-family "Roboto Condensed"
	font-weight 400
	padding-left 37px
.nav 
	margin-top 60px
	ul
		li 
			float left
			padding-right 52px
			padding 0 26px
			&:after 
				position absolute
				content ""
				background url('../images/ico-li.png')
				width 7px 
				height 7px
				right -2px
				top 5px
			&:last-child:after 
				display none
			&:last-child
				padding-right 0
			&:first-child
				padding-left 0

			a 
				font-family 'GothamPro-Bold'
				color #000000
				display inline-block
				text-decoration none
				font-size 15px
				text-transform uppercase
				padding-bottom 10px
				&:hover 
					color #8e44ad
		li.active a 
			border-bottom 4px solid #9b59b6
.slogan 
	background url('../images/bg-slogan.png')
	width 266px
	height 139px
	position absolute
	top 120px
	z-index 999
.slider 
	width 566px 
	margin 0 auto
	text-align center
	&:before 
		position absolute
		content ""
		background url('../images/f-slider-left.png')
		width 103px 
		height 167px
		z-index 99
		margin-top 415px
		left -81px
	&:after 
		position absolute
		content ""
		background url('../images/f-slider-right.png')
		width 153px 
		height 102px
		z-index 99
		right -120px
		top 56px
.slider-arrow 
	position absolute
	width 100%
	a 
		position absolute
		display block
		background url('../images/slider-arrow.png')
		z-index 99
		width 28px
		height 55px
		&:hover 
			opacity .6
	a.prev 
		background-position 0 0
		top -220px
	a.next 
		background-position -33px 0
		top -426px
		right 10px
.slider .bx-wrapper img 
	display inline-block

.content 
	min-height 600px
	color #000
	font-size 18px
	font-family: 'Roboto Condensed', sans-serif;
	font-weight 300
	margin-bottom 35px
	p.center 
		display block
		text-align center
	img 
		border 5px solid #9b59b6
	p 
		margin-bottom 15px
	p strong 
		font-weight 700
		color #9b59b6
	.title 
		margin-top 55px
		color #000
		font-size 26px
		text-transform uppercase
		margin-bottom 20px
	.bread 
		margin-top 35px
		ul 
			li
				float left
				margin-right 5px
				color #7f8c8d
				a 
					color #7f8c8d
.footer 
	height 100px
	.box 
		width 250px 
		text-align right
		position absolute
		right 0
		.phone 
			color #000
			font-size 18px
			font-family 'GothamPro-Bold'
		.social
			margin-top 10px
			a 
				display inline-block
				margin-right 10px 
				width 30px 
				height 30px
				transition: .4s
				&:last-child					 
					margin-right 0
				&:hover 
					-webkit-filter grayscale(100%)
					filter grayscale(100%)
			a.ok 
				background url('../images/ok.png')
			a.tw 
				background url('../images/tw.png')
			a.vk 
				background url('../images/vk.png')
			a.fb 
				background url('../images/fb.png')
			a.y 
				background url('../images/y.png')
			a.ins
				background url('../images/ins.png')
.p-row .item img 
	width 100%
	position relative
.p-row .item 
	.col 
		position relative
		.name 
			font-weight 500
			font-size 20px
			padding-bottom 15px
		.post 
			padding-bottom 15px
		.desc 
			font-style italic
	.col:nth-child(1)
		width 30%
	.col:nth-child(2)
		width 70%
		padding-left 75px
.row-map .col:nth-child(1) 
	width 60%
.row-map .col:nth-child(2) 
	width 40%
	line-height 25px
	span 
		color #7f8c8d
.animate-line
	position absolute
	content ""
	height 10px
	width 100%
	background-color #8e44ad
	top 103px
.row-peoples
	.col 
		width 50%
#map 
	width 100%
	height 500px
	margin-top 35px
	border-top 5px solid #8e44ad
	border-bottom 5px solid #8e44ad
	box-sizing border-box
.box-map 
	position absolute
	width 400px
	height 147px
	background-color #8e44ad
	right 0
	top 44px
	padding-left 35px
	padding-top 15px
	padding-right 35px
	.c-title
		color #fff
		font-size 32px
		text-transform uppercase
	.c-addres 
		color #fff
		margin-top 20px
	.c-link 
		margin-top 10px
		a 
			color #fff
.footer 
	.col:first-child
		width 70%
	.col:last-child 
		width 30%
.chm 
	line-height 25px
	font-size 14px
	padding-left 15px
	&:after 
		position absolute
		content ""
		height 36px 
		width 2px
		background-color #8e44ad
		left 0px
		top 8px
.slider 
	min-height 616px
	cursor pointer
.slider-arrow
	display none
.main:hover .slider-arrow
	display block !important
.row-gallery 
	.col 
		width 33.333%
		margin-bottom 25px
		.item 
			display block
			background-color #fff
			width 294px
			height 294px
			img 
				border 5px solid #8e44ad
				box-shadow: -1.7px 1px 17.1px 0.9px rgba(142, 68, 173, 0.31);
			.name 
				position absolute
				bottom 10px
				margin-left 0px
				color #fff 
				background-color #8e44ad
				padding 10px 15px
				display block
				text-decoration none
				opacity .9
.row-faq 
	margin-top 35px
	ul 
		li 
			.q
				background-color #8e44ad
				border 1px solid #8e44ad
				padding 10px 15px
				color #fff 
				cursor pointer
				transition .4s
				margin-bottom 10px
				font-size 20px
				font-weight 300
				text-transform uppercase
				&:hover 
					background none 
					color #8e44ad
			.a 
				padding-left 15px
				display none
.row-achievements
	.center 
		text-align center
		margin 35px 0
	.col-4
		margin 35px 0
		.col 
			width 25%
	.col-2 
		margin-bottom 45px
		.col:first-child 
			width 70%
			padding-right 25px
			padding-top 15px
		.col:last-child 
			width 30%
.row-news 
	.col:first-child
		width 170px
		img 
			width 170px
	.col:last-child
		width 800px
		padding-left 20px
		.name 
			a 
				display inline-block
				padding 5px 0
				text-decoration none
				color #9b59b6
				font-size 20px
				text-transform uppercase
				font-weight 500
		a.more 
			display inline-block
			text-transform none
			text-decoration none
			background-color #9b59b6
			border 1px solid #9b59b6
			padding 5px 20px
			color #fff
			transition .4s 
			&:hover 
				background none
				color #9b59b6
		.btn-more 
			text-align right
.item-news 
	margin-bottom 40px
.p-row .item 
	border-bottom 1px dotted #9349b2
	padding-bottom 35px
	margin-top 35px
	.name 
		color #9349b2