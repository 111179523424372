.mfp-bg
  top 0
  left 0
  width 100%
  height 100%
  z-index 1042
  overflow hidden
  position fixed
  background #0b0b0b
  opacity 0.8

.mfp-wrap
  top 0
  left 0
  width 100%
  height 100%
  z-index 1043
  position fixed
  outline none !important
  -webkit-backface-visibility hidden

.mfp-container
  text-align center
  position absolute
  width 100%
  height 100%
  left 0
  top 0
  padding 0 8px
  box-sizing border-box
  &:before
    content ''
    display inline-block
    height 100%
    vertical-align middle

.mfp-align-top
  .mfp-container
    &:before
      display none

.mfp-content
  position relative
  display inline-block
  vertical-align middle
  margin 0 auto
  text-align left
  z-index 1045

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content
  width 100%
  cursor auto

.mfp-ajax-cur
  cursor progress

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close
  cursor -moz-zoom-out
  cursor -webkit-zoom-out
  cursor zoom-out

.mfp-zoom
  cursor pointer
  cursor -webkit-zoom-in
  cursor -moz-zoom-in
  cursor zoom-in

.mfp-auto-cursor
  .mfp-content
    cursor auto

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter
  -webkit-user-select none
  -moz-user-select none
  user-select none

.mfp-loading
  &.mfp-figure
    display none

.mfp-hide
  display none !important

.mfp-preloader
  color #CCC
  position absolute
  top 50%
  width auto
  text-align center
  margin-top -0.8em
  left 8px
  right 8px
  z-index 1044
  a
    color #CCC
    &:hover
      color #FFF

.mfp-s-ready
  .mfp-preloader
    display none

.mfp-s-error
  .mfp-content
    display none

button.mfp-close,
button.mfp-arrow
  overflow visible
  cursor pointer
  background transparent
  border 0
  -webkit-appearance none
  display block
  outline none
  padding 0
  z-index 1046
  box-shadow none
  touch-action manipulation

button
  &::-moz-focus-inner
    padding 0
    border 0

.mfp-close
  width 44px
  height 44px
  line-height 44px
  position absolute
  right 0
  top 0
  text-decoration none
  text-align center
  opacity 0.65
  padding 0 0 18px 10px
  color #FFF
  font-style normal
  font-size 28px
  font-family Arial, Baskerville, monospace
  &:active
    top 1px

.mfp-close:hover,
  .mfp-close:focus
  opacity 1

.mfp-close-btn-in
  .mfp-close
    color #333

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close
  color #FFF
  right -6px
  text-align right
  padding-right 6px
  width 100%

.mfp-counter
  position absolute
  top 0
  right 0
  color #CCC
  font-size 12px
  line-height 18px
  white-space nowrap

.mfp-arrow
  position absolute
  opacity 0.65
  margin 0
  top 50%
  margin-top -55px
  padding 0
  width 90px
  height 110px
  -webkit-tap-highlight-color transparent
  &:active
    margin-top -54px
  &:after
    border-top-width 13px
    border-bottom-width 13px
    top 8px
  &:before
    border-top-width 21px
    border-bottom-width 21px
    opacity 0.7

.mfp-arrow:hover,
  .mfp-arrow:focus
  opacity 1

.mfp-arrow:before,
  .mfp-arrow:after
  content ''
  display block
  width 0
  height 0
  position absolute
  left 0
  top 0
  margin-top 35px
  margin-left 35px
  border medium inset transparent

.mfp-arrow-left
  left 0
  &:after
    border-right 17px solid #FFF
    margin-left 31px
  &:before
    margin-left 25px
    border-right 27px solid #3F3F3F

.mfp-arrow-right
  right 0
  &:after
    border-left 17px solid #FFF
    margin-left 39px
  &:before
    border-left 27px solid #3F3F3F

.mfp-iframe-holder
  padding-top 40px
  padding-bottom 40px
  .mfp-content
    line-height 0
    width 100%
    max-width 900px
  .mfp-close
    top -40px

.mfp-iframe-scaler
  width 100%
  height 0
  overflow hidden
  padding-top 56.25%
  iframe
    position absolute
    display block
    top 0
    left 0
    width 100%
    height 100%
    box-shadow 0 0 8px rgba(0, 0, 0, 0.6)
    background #000

img
  &.mfp-img
    width auto
    max-width 100%
    height auto
    display block
    line-height 0
    box-sizing border-box
    padding 40px 0 40px
    margin 0 auto

.mfp-figure
  line-height 0
  &:after
    content ''
    position absolute
    left 0
    top 40px
    bottom 40px
    display block
    right 0
    width auto
    height auto
    z-index -1
    box-shadow 0 0 8px rgba(0, 0, 0, 0.6)
    background #444
  small
    color #BDBDBD
    display block
    font-size 12px
    line-height 14px
  figure
    margin 0

.mfp-bottom-bar
  margin-top -36px
  position absolute
  top 100%
  left 0
  width 100%
  cursor auto

.mfp-title
  text-align left
  line-height 18px
  color #F3F3F3
  word-wrap break-word
  padding-right 36px

.mfp-image-holder
  .mfp-content
    max-width 100%

.mfp-gallery
  .mfp-image-holder
    .mfp-figure
      cursor pointer

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px)
  .mfp-img-mobile
    .mfp-image-holder
      padding-left 0
      padding-right 0
    img
      &.mfp-img
        padding 0
    .mfp-figure
      &:after
        top 0
        bottom 0
      small
        display inline
        margin-left 5px
    .mfp-bottom-bar
      background rgba(0, 0, 0, 0.6)
      bottom 0
      margin 0
      top auto
      padding 3px 5px
      position fixed
      box-sizing border-box
      &:empty
        padding 0
    .mfp-counter
      right 5px
      top 3px
    .mfp-close
      top 0
      right 0
      width 35px
      height 35px
      line-height 35px
      background rgba(0, 0, 0, 0.6)
      position fixed
      text-align center
      padding 0

@media all and (max-width: 900px)
  .mfp-arrow
    -webkit-transform scale(0.75)
    transform scale(0.75)
  .mfp-arrow-left
    -webkit-transform-origin 0
    transform-origin 0
  .mfp-arrow-right
    -webkit-transform-origin 100%
    transform-origin 100%
  .mfp-container
    padding-left 6px
    padding-right 6px

