html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video
  border 0
  font-size 100%
  margin 0
  padding 0

li
  list-style-type none

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section
  display block

ol,
ul
  list-style none

blockquote,
q
  quotes none

blockquote:before,
blockquote:after,
q:before,
q:after
  content ''
  content none

body
  font-family: 'Open Sans', sans-serif;
  font-size 14px
  font-weight 400
  color #757381

table
  border-collapse collapse
  border-spacing 0

a
  &:hover
    text-decoration none

*
  box-sizing border-box
  position relative

.validation
  border 1px solid rgb(255, 0, 0) !important
  box-shadow 0 0 10px rgba(255, 0, 0, .1) !important

.container
  margin 0 auto
  position relative
  width $containerWidth
  z-index 100

section
  min-width $containerWidth
  width 100%

.row
  position relative
  width 100%

.container:before,
.container:after,
.cf:before,
.cf:after,
.row:before,
.row:after
  content ""
  display table

.container:after,
.cf:after,
.row:after
  clear both

.container,
.cf. .row
  zoom 1

.fl
  float left

.hide
  display none

.show
  display block

.col
  float left